<template>
  <div class="flex min-h-screen flex-col">
    <AppHeader :initial-data="initialData" />
    <div class="flex grow flex-col">
      <slot />
    </div>
    <DelayHydration>
      <LazyAppFooter
        :infobox="initialData?.infobox"
        :newsletter="initialData?.newsletter"
        :social="initialData?.social"
        :footer-navigation="initialData?.footerNavigation"
      />
    </DelayHydration>

    <DelayHydration>
      <LazyUiFloatingIcons
        v-show="floatingIcons?.length"
        :icons="floatingIcons"
      />
    </DelayHydration>

    <ClientOnly>
      <DelayHydration>
        <LazyUiTransition name="slide-top-in-bottom-out">
          <LazyUiMobileBar
            v-if="mobileMenuOpen"
            :labels="{ search: t('search') }"
            :links="mobileBarLinks"
            :items-in-cart="itemsInCart"
            @hamburger-click="toggleAppNav(null, true)"
            @search-click="toggleSearchOverlay"
          />
        </LazyUiTransition>
      </DelayHydration>
    </ClientOnly>

    <DelayHydration>
      <LazyAppDrawer />
      <LazyAppNavDrawer />
    </DelayHydration>

    <DelayHydration>
      <component :is="isEcommerceEnabled ? 'LazyAppReportErrorModal' : null" />
    </DelayHydration>
  </div>
</template>

<script lang="ts" setup>
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { useDrawer } from '@base/components/AppDrawer/useDrawer'
import useConditionalRender from '@base/composables/useConditionalRender/useConditionalRender'
import { RawlplugUiMqKey } from '@ui/plugin'
import type { RawlplugUiMq } from '@ui/plugin'
import { useFloatingIcons } from '@cms/composables/useFloatingIcons/useFloatingIcons'

const importUseCustomer = () => import('../../rawlplug-customer/composables/useCustomer')
type UseCustomerType = Awaited<ReturnType<typeof importUseCustomer>>['useCustomer']

const { t } = useI18n()
const { initialData } = useT3Api()
const { toggleAppNav, toggleAppAccount, toggleSearchOverlay } = useDrawer()
const { initNavigation } = useAppNav()
await initNavigation(initialData.value?.navigation)

const { isEcommerceEnabled } = useConditionalRender()

const { isMobile } = (inject(RawlplugUiMqKey) as RawlplugUiMq) || {}

const useCustomer = ref<UseCustomerType>()

const { floatingIcons } = useFloatingIcons()

const mobileMenuOpen = ref(false)
function toggleMobileMenu() {
  if (isMobile.value) {
    nextTick(() => {
      mobileMenuOpen.value = true
    })
  }
  else {
    mobileMenuOpen.value = false
  }
}

const { localePath } = useT3Utils()

if (isEcommerceEnabled.value) {
  const { useCustomer: useCustomerData } = await importUseCustomer() || {}
  useCustomer.value = useCustomerData
}

const itemsInCart = computed(() => {
  if (useCustomer?.value?.()) {
    const { customerData } = useCustomer.value()
    return customerData.value?.cart?.summary_count || 0
  }
  return 0
})

const mobileBarCustomerIcon = computed(() => {
  if (useCustomer?.value?.()) {
    const { isLoggedIn } = useCustomer.value()

    if (isLoggedIn.value) {
      return {
        id: 'account',
        title: t('dashboard'),
        icon: 'user',
        link: localePath('/account'),
        width: 20,
        height: 20,
      }
    }

    return {
      id: 'login',
      title: t('cms_log_in'),
      icon: 'login',
      callback: () => toggleAppAccount(),
      width: 24,
      height: 24,
    }
  }

  return {}
})

const mobileBarLinks = computed(() => [
  mobileBarCustomerIcon.value,
  {
    id: 'cart',
    title: t('cms_cart'),
    icon: 'cart',
    link: localePath('/checkout/cart'),
    width: 24,
    height: 18,
  },
  {
    id: 'products',
    title: t('cms_products'),
    icon: 'drill3',
    link: localePath('/c/products'),
    width: 24,
    height: 24,
  },
])

watch(isMobile, () => {
  toggleMobileMenu()
})

onMounted(() => {
  toggleMobileMenu()
})
</script>
